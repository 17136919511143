table {
  table-layout: fixed;
}

td {
  word-wrap: break-word;
  padding: 0.5rem !important;
}

small {
  color: #6c757d !important;
}

.summary-request {
  max-width: 100%;
  word-wrap: break-word;
}

